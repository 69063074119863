<template>
  <div class='py-12 flex flex-row'>
    <label class='text-2xl text-left' style='width:400px'>회수 완료</label>
    <v-chart class='chart bg-white' :option='pieChart' style='height: 400px; width:400px;' />
  </div>
</template>

<script>
import { PieChart } from 'echarts/charts'
import { use } from 'echarts/core'
use([
  PieChart
])
export default {
  name: 'CollectionChart',
  props: [
    'collectionChartData',
  ],
  data() {
    return {
      pieChart: {
        title: {
          text: '',
          subtext: '회수이익(억원)',
          x: 'center'
        },
        series: [
          {
            name: '회수이익',
            type: 'pie',
            radius: [30, 90],
            center: ['50%', '50%'],
            color: [
              '#3267A2',
              '#52527F',
              '#90969B',
              '#c7bb93',
              '#948073',
              '#9093e8',
              '#e6b99c',
              '#bfcc89',
              '#9ac28d',
              '#9ac28d',
              '#82495e',
              '#251c5c',
            ],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      }
    }
  },
  watch: {
    'collectionChartData.length': {
      handler: function () {
        this.refreshData ()
      },
      immediate: true
    },
  },
  methods: {
    refreshData () {
      this.pieChart.series[0].data = this.collectionChartData
    }
  },
}
</script>
